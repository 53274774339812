import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi, I'm Michael Sheedy and I've been a designer for twenty years. For the last seven years I've helped startups, agencies, and brands like Apple, Cisco, and Century Link with Product Design. After experiencing firsthand the transformative power that good customer data can have on software companies, I made it central to how I help.`}</p>
    <p>{`With the right strategy and implementation, any team or individual can use customer data to make every aspect of the company and the customer's experience better.`}</p>
    <p>{`If you'd like to talk, get in touch by `}<a parentName="p" {...{
        "href": "mailto:m@michaelsheedy.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`email`}</a>{` or `}<a parentName="p" {...{
        "href": "https://sheedy.youcanbook.me/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`book a call`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      