import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { Flex, Box } from "reflexbox"
import BioCopy from "./bio.mdx"

import "../styles/main.scss"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      headShot: file(relativePath: { eq: "bio-head-sml-circle.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          gatsbyImageData(
            formats: [AUTO, WEBP]
            layout: FIXED
            width: 150
            transformOptions: {
              duotone: {
                highlight: "#fdf8f4"
                shadow: "#6a4116"
                # shadow: "#21303d" # blue
              }
            }
          )
        }
      }
      ampImp: file(
        relativePath: { eq: "cert-amplitude-implementation-expert.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], layout: FIXED, width: 150)
        }
      }
      ampIns: file(relativePath: { eq: "cert-amplitude-insights-expert.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], layout: FIXED, width: 150)
        }
      }
      segment: file(relativePath: { eq: "cert-segment-horizontal-gray.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], layout: FIXED, height: 25)
        }
      }
    }
  `)

  return (
    <>
      <Flex
        flexWrap="wrap"
        // className="u-p+ u-mb++"
        p={[3, 4]}
        mb={[5, 6]}
        style={{
          backgroundColor: `#fff`,
          borderRadius: `8px`,
        }}
      >
        <Box width={[1]} textAlign={[`center`]}>
          <Img
            image={getImage(data.headShot)}
            alt="Headshot"
            className="u-mb u-d-inline-block"
          />
        </Box>
        <Box width={[1]} className="u-pb-">
          <MDXProvider>
            <BioCopy />
          </MDXProvider>
        </Box>
        <Flex
          width={[1]}
          flexWrap="wrap"
          justifyContent="space-evenly"
          alignItems="center"
          textAlign={[`center`]}
        >
          <Box width={[1, `auto`]}>
            <Img
              image={getImage(data.ampIns)}
              className="u-d-inline-block"
              alt="Amplitude Certified Insights Expert"
            />
          </Box>
          <Box width={[1, `auto`]} order={[-1, 0]}>
            <Img
              image={getImage(data.segment)}
              className="u-d-inline-block"
              alt="Segment Certified Partner"
            />
          </Box>
          <Box width={[1, `auto`]}>
            <Img
              image={getImage(data.ampImp)}
              className="u-d-inline-block"
              alt="Amplitude Certified Implementation Expert"
            />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default Bio
